.App {
  text-align: center;
}
@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./assets/fonts/din.ttf) format('opentype');
}
@font-face {
  font-family: 'DIN';
  font-weight: 500;
  src: local('DIN'), url(./assets/fonts/din_medium.ttf) format('opentype');
}
.centerText{
  text-align: center;
}
.notDisplay{
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.myLoginText{
  text-align: center;
  font-size: 12px;
  color: #5ba4a0;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  font-family: DIN, Arial, serif!important;
  font-weight: 100!important;
}

html {
  overflow-x: hidden;
  max-width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.myEyeButton{
  cursor: pointer;
  position: absolute;
  top: 44px;
  right: 10px;
  color: #5ba4a0;
}

.App-link {
  color: #61dafb;
}
h2{
  font-family: DIN, Arial, serif;
  font-weight: 500;
  font-size: 30px;
}
.myCustomModalPersonal{
  padding: 20px;
  background-color: #f3f6f6;
}
.myCustomModalAddress{
  padding: 20px;
  background-color: #f3f6f6;
}
.myFormWidth {
  width: 100%;
  margin-bottom: 25px!important;
}

.halfWidth {
  width: 50%;
  padding: 5px!important;
  margin-bottom: 25px!important;
}
.myMaxText {
  float: right;
  font-weight: 200;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
}
.myHelper{
color: #b1b3b5;
font-size: 14px;
text-decoration: underline;
cursor: pointer;
float: right;
}
.myHelperText{
font-size: 13px;
margin-top: 4px;
margin-bottom: 4px!important;
max-width: 300px;
}
.overlay {
left: 0;
top: 0;
width: 100%;
height: 100%;
position: fixed;
background: rgba(0, 0, 0, 0.65);
}

.overlay__inner {
left: 0;
top: 0;
width: 100%;
height: 100%;
position: absolute;
}

.overlay__content {
left: 50%;
position: absolute;
top: 50%;
transform: translate(-50%, -50%);
}

.spinner {
width: 75px;
height: 75px;
display: inline-block;
border-width: 2px;
border-color: rgba(255, 255, 255, 0.5);
border-top-color: #5ba4a0;
animation: spin 1s infinite linear;
border-radius: 100%;
border-style: solid;
}

@keyframes spin {
100% {
transform: rotate(360deg);
}
}
@media only screen and (max-width:37.5em) {
.halfWidth {
width: 100%;
}
.myAccess{
margin-top: 5px;
}
}

.bg-image {
height: 100vh;
position: absolute;
top: 0;
width: 100vw;
backdrop-filter: blur(10px);
}

.myButtonOutlined{
position: relative;
display: inline-block;
padding: 0 30px;
line-height: 40px;
border-radius: 2px;
text-align: center;
font-weight: 200;
background: transparent;
border: 1px solid #5ba4a0;
color: #5ba4a0;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
float: right;
}

.myButtonOutlinedRed{
  position: relative;
  display: inline-block;
  padding: 0 30px;
  line-height: 40px;
  border-radius: 2px;
  text-align: center;
  font-weight: 200;
  background: transparent;
  border: 1px solid red;
  color: red;
  cursor: pointer;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  float: right;
}

.myButtonText{
position: relative;
display: inline-block;
line-height: 40px;
border-radius: 2px;
text-align: center;
font-weight: 200;
background: transparent;
border: none;
color: grey;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
float: right;
}
.myButtonSecondaryOutlined{
position: relative;
display: inline-block;
padding: 0 30px;
line-height: 40px;
border-radius: 2px;
text-align: center;
font-weight: 200;
background: transparent;
color: grey;
border: 1px solid grey;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
float: right;
}
.myAccess{
float: right;
margin-right: 35px;
margin-top: 25px;
color: #bdbcbc;
cursor: pointer;
}
.myButtonSecondaryOutlined:hover{
background: grey;
color: #fff;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}

.myButtonOutlined:hover{
background: #5ba4a0;
color: #fff;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
.myContainer{
display: flex;
align-items: center;
margin: 0!important;
}
.myDanger{
margin: 0 auto;
margin-top: 15px;
padding: 7px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 90%;
border-radius: 5px;
background-color: #f56147;
color: white;
cursor: pointer;
}

.myHideInfo{
font-size: 14px;
color: #474747;
}

.myRecoverButton{
text-align: right;
font-size: 12px;
color: #5ba4a0;
cursor: pointer;
font-weight: 700;
margin-top: 5px;
margin-bottom: 5px;
}

.myButton {
position: relative;
display: inline-block;
padding: 0 30px;
line-height: 48px;
border-radius: 2px;
text-align: center;
font-weight: 200;
border: none;
background: #5ba4a0;
color: white;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
float: right;
}
.myButtonDisabled {
position: relative;
display: inline-block;
line-height: 48px;
border-radius: 2px;
text-align: center;
font-weight: 200;
border: none;
background: transparent;
color: black;
cursor: pointer;
transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
float: right;
}
.myButtonDisabled:disabled{
color: rgba(0, 0, 0, 0.3);
}
.myExpansionPanel{
padding: 5px;
background-color: rgb(246, 246, 249)!important;
-webkit-box-shadow: none!important;
-moz-box-shadow: none!important;
box-shadow: none!important;
}
.myRedText{
color: #f56147;
}
.myPriceTh{
font-size: 18px;
}
.mySpacerButton{
padding-top: 20px;
float: left;
}
.myBadge{
float: right;
font-size: 10px;
background-color: #d0cfcf;
padding-top: 5px;
padding-right: 9px;
padding-left: 9px;
padding-bottom: 5px;
color: #ffffff;
border-radius: 15px;
}
.MuiExpansionPanel-root:before{
display: none;
}
.myResume{
padding: 5px;
background-color: rgb(246, 246, 249);
}
td{
padding: 15px;
}
th{
padding-top: 15px;
padding-right: 15px;
padding-left: 15px;
font-weight: 300;
font-size: 12px;
text-transform: uppercase;
}
.offerDisp{
border-radius: 2px;
width: 80px;
height: 35px;
cursor: pointer;
text-align: center;
margin-left: 30px;
padding: 10px!important;
font-size: 13px;
background: transparent;
color: #5ba4a0;
border: 1px solid #5ba4a0;
}
.containerInfo{
  margin: 10px;
  padding: 10px;
  background-color: #5ba4a0;
  color: white;
}
.myPHeading{
padding-top: 30px;
padding-right: 30px;
padding-left: 30px;
font-weight: 300;
font-size: 12px;
text-transform: uppercase;
}
.myP{
padding-top: 30px;
padding-right: 30px;
padding-left: 30px;
font-weight: 300;
}
.myButton:disabled {
background: rgba(91, 164, 160, 0.5);
}
.myButtonChangeType{
margin-left: auto;
color: gray;
font-size: 12px;
cursor: pointer;
}
.deleteButton{
  text-align: center;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 15px;
  font-size: 16px;
  text-decoration: underline;
  color: #b80000;
}

.deleteButton2{
  position: relative;
  display: inline-block;
  padding: 0 30px;
  line-height: 48px;
  border-radius: 2px;
  text-align: center;
  font-weight: 200;
  border: none;
  background: #b80000;
  color: white;
  cursor: pointer;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  float: right;
}
.myButton{
  font-weight: 500;
}
button{
  font-weight: bold;
}
.card i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.card {
  transform: translate(0%, 80%);
  text-align: center;
  background: transparent;
  padding: 60px;
  border: none!important;
  display: inline-block;
  margin: 0 auto;
  width: 97%;
  padding: 20px;
}
*{
  font-family: "Roboto";
}
@media (max-width:481px)  {
  .myAccess{
    margin-top: 3px;
  }
}

